// ==================================================================
// Colors
// ==================================================================

/// Color value for gray. (Main Background)
/// Sample Usage: Main Background
/// @group gray
/// @type String
$sprk-gray-alternative: #f2f4f7;

/// Color value for gray.
/// Sample Usage: Elemental Backgrounds
/// @group gray
/// @type String
$sprk-gray-alternative-light: #fcfcfc;

/// Color value for gray.
/// Sample Usage: Table Headers
/// @group gray
/// @type String
$sprk-gray-alternative-dark: #e4eaf0;
