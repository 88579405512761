.embeddedServiceSidebar .activeFeature {
    border-top: 1px solid black !important;
  }
  
.sidebarHeader {
  margin-bottom : 10px !important;
}

.embeddedServiceHelpButton {
  visibility: hidden;
}
  
.embeddedServiceHelpButton .embeddedServiceIcon {
  width: 15px;
}
  
.embeddedServiceHelpButton .helpButton .helpButtonEnabled:hover::before,
.embeddedServiceHelpButton .helpButton .helpButtonEnabled:focus::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 23px;
  background-color: #f3f2f2 !important;
  opacity: .2;
  pointer-events: none;
}
  
.embeddedServiceHelpButton .embeddedServiceIcon::before {
  font-family: "embeddedserviceiconfont";
  font-size: 1.25em;
  content: url(https://rocketmortgage.secure.force.com/liveAgentSetupFlow/resource/ChatIcon) !important;
  speak: none;
  text-rendering: auto;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff !important;
}

.embeddedServiceHelpButton .uiButton:after {
  content: "";
  display: block;
  float: none;
  margin: 5px 0 5px 15px;
  width: 14px !important;
  height: 14px !important;
  background: #08a38f;
  border-radius: 50px;
  position: absolute;
  right: 6px;
}

.embeddedServiceHelpButton .uiButton .helpButtonLabel .message {
  overflow: visible;
}

.helpButton {
  width: 185px !important;
  margin-bottom: 10px !important;
}

.embeddedServiceHelpButton .helpButton .uiButton {
  box-sizing: border-box;
  margin: 0;
  padding: 0 12px;
  height: 46px;
  line-height: 1;
  background: #ffffff !important;
  font-size: .875em;
  font-size: 10px;
  color: #C8102E !important;
  fill: #C8102E !important;
}

.embeddedServiceHelpButton .helpButton .uiButton {
  box-sizing: border-box;
  margin: 0;
  padding: 0 12px;
  height: 46px;
  line-height: 1;
  background: #ffffff !important;
  font-size: .875em;
  font-size: 10px;
  color: #ffffff !important;
}

.message {
  background-color: white !important;
  color: black !important;
  border-style: none !important;
  font-size: small !important;
  font-weight: bold !important;
  font-family: 'Rocket Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

.helpButtonEnabled {
  bottom: 1px !important;
  border-radius: 40px !important;
  min-width: 185px !important;
}

.helpButtonDisabled {
  bottom: 1px !important;
  border-radius: 40px !important;
  min-width: 185px !important;
}

.backgroundImg {
  background-size: contain !important;
  margin-left: 10px;
  margin-right: 10px;
}

.backgroundImgColorMask {
  background-color: white !important;
}

.greetingTextStyle {
  font-size: 23px;
  color: black !important;
  font-weight: bold;
  padding: 10px;
  text-align: left;
}

.helpTextContentStyle {
  text-align: left;
  padding: 10px;
  color: black;
}

// my additions

.embeddedServiceHelpButton .uiButton.helpButtonDisabled:after {
  background-color: #C8102E !important;
}

.embeddedServiceLiveAgentStateChatInputFooter .chasitorText {
  font-family: 'Rocket Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

.embeddedServiceLiveAgentStateChatItem.chatMessage {
  margin-bottom: 8px !important;
  overflow: inherit !important;
}

.embeddedServiceLiveAgentStateChatItem .isLightningOutContext .avatar {
  margin-bottom: -8 !important;
  position: inherit;
}

.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.plaintextContent {
  margin-left: 0px;
}

.embeddedServiceSidebar.modalContainer {
  font-family: 'Rocket Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

.embeddedServiceSidebarButton {
  background: #C8102E !important;
  font-family: 'Rocket Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

.embeddedServiceSidebarButton.uiButton--inverse .label {
  color: #ffffff !important;
}

.embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):focus, .embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):hover {
  text-decoration-color: #ffffff !important;
}

.embeddedServiceSidebarFormField .slds-style-inputtext, .embeddedServiceSidebarFormField .slds-style-select {
  font-family: 'Rocket Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  border: 2px solid #d2d1d1 !important;
}

.embeddedServiceSidebarFormField .slds-style-inputtext:focus, .embeddedServiceSidebarFormField .slds-style-select:focus {
  border: 2px solid #603aa1 !important;
  box-shadow: none !important;
}

.embeddedServiceSidebarMinimizedDefaultUI.helpButton {
  border-width: 0px !important;
}

.embeddedServiceSidebarMinimizedDefaultUI.helpButton .messageContent {
  justify-content: space-between !important;
}

.embeddedServiceSidebarMinimizedDefaultUI.newMessage, .embeddedServiceSidebarMinimizedDefaultUI.idleTimeout {
  background-color: #ffffff !important;
}

.embeddedServiceSidebarMinimizedDefaultUI .embeddedServiceIcon {
  content: url(https://rocketmortgage.secure.force.com/liveAgentSetupFlow/resource/ChatIcon) !important
}

.endChatButton .closeChatButton .uiButton .embeddedServiceSidebarButton {
  margin-top: 4px !important;
}

.endChatButton.closeChatButton.uiButton--default.uiButton.embeddedServiceSidebarButton {
  margin-bottom: 10px !important;
}

.label {
  font-size: var(--lwc-esFontSizeMedium,1em);
  font-weight: 500;
}

label[for=Subject] {
  display: none;
}

.minimizedText:after{
  content: "";
  display: block;
  float: none;
  margin: 5px 0 5px 15px;
  width: 14px !important;
  height: 14px !important;
  background: #08a38f;
  border-radius: 50px;
  right: 6px;
  position: absolute !important;
}

.sidebarHeader {
  border-width: 0px !important;
}

.sidebarHeader h2 {
  overflow: initial !important;
}

.sidebarHeader.minimizedContainer div div .minimizedText:after { 
  position: inherit !important;
}

.wrapper {
  height: auto !important;
  min-height: fit-content !important;
}

.iconAvatar {
	content: url(https://rocketmortgage.secure.force.com/liveAgentSetupFlow/resource/MagicORocketIogo);
}

.embeddedServiceLiveAgentStateChatAvatar.isLightningOutContext .agentIconColor0 {
	background-color: #f3f2f2 !important;
}

//styling for mobile experience
@media only screen and (max-width: 480px) {

  .embeddedServiceHelpButton .uiButton::after {
    right: 4px!important;
    bottom: 53px!important;
    width: 16px !important;
    height: 16px !important;
  }

  .embeddedServiceSidebarMinimizedDefaultUI {
    bottom: 0 !important;
  }

  .embeddedServiceSidebarMinimizedDefaultUI:not(.waiting) .minimizedText {
    flex-direction: row !important;
  }

  .embeddedServiceSidebarMinimizedDefaultUI.helpButton {
    box-sizing: border-box;
    margin: 0;
    padding: 0 12px;
    height: 46px;
    line-height: 1;
    background: #ffffff !important;
    font-size: .875em;
    font-size: 10px;
    color: #ffffff !important;
  }

  .embeddedServiceSidebarMinimizedDefaultUI .messageContent {
    flex-grow: 0 !important;
  }

  .embeddedServiceSidebarMinimizedDefaultUI .embeddedServiceIcon {
    z-index: 0 !important;
  }

  .helpButton {
    min-height: 70px !important;
    width: 65px !important;
    right: 0px !important;
    margin-right: 30px !important;
  }

  .helpButtonEnabled,
  .helpButtonDisabled {
    min-height: 72px !important;
    min-width: 0px !important;
    max-width: 65px !important;
  }

  .helpButtonEnabled .helpButtonLabel {
    right: 6vw !important;
  }

  .helpButtonEnabled .helpButtonLabel .message {
    padding-left: 5px !important;
  }

  .helpButtonDisabled {
    position: absolute !important;
  }

  .helpButtonDisabled .helpButtonLabel .message {
    padding-left: 12px !important;
  }

  .helpButtonLabel {
    display: block !important;
    overflow: visible !important;
    right: 38px !important;
    top: 50px !important;
  }

  .message {
    background: transparent !important;
    color: #C8102E !important;
    overflow: visible !important;
  }

  .minimizedText:after {
    top: 0px !important;
    right: 0px !important;
  }

  .minimizedText .message {
    position: absolute !important;
    right: 8px !important;
    bottom: 2px !important;
  }

  .minimizedContainer {
    min-width: 0 !important;
  }

  .sidebarHeader.minimizedContainer div div .minimizedText:after {
    position: absolute !important;
  }

  .uiButton .no-hover .helpButtonDisabled::after {
    background: #c8102e !important;
  }
}