.sprk-b-Table--gray {
  th {
    background-color: $sprk-gray-alternative-dark;
  }

  tbody {
    background-color: white;
  }
}

.sprk-b-Table--borders {
  tbody {
    tr {
      border-bottom: 1px solid $sprk-gray-alternative-dark;
    }
  }
}

.row-clickable {
  tr:hover {
    cursor: pointer;
    background-color: $sprk-gray-alternative-dark;
  }
  tr.notice {
    pointer-events: none;
  }
}

td.notice {
  padding-top: 0;
}

.sprk-b-Table--white-head {
  th {
    background-color: white;
  }
}