.progress-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: 1rem auto;
  border: 1px solid #bebebe;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  transition: ease-in 0.5s;
  -webkit-transition: ease-in 0.5s;
  -moz-transition: ease-in 0.5s;
  -ms-transition: ease-in 0.5s;
  -o-transition: ease-in 0.5s;

  &__content {
    display: flex;
    flex-flow: column;
    flex: 1;
    order: 1;
    flex-shrink: 0;
  }

  &__actions {
    align-self: center;
    order: 2;
    flex-shrink: 0;
  }

  &__action {
    cursor: pointer;
    font-size: 1.5rem;
    color: #808080;

    &:hover {
      color: #404040;
    }
  }

  &__status-information {
    order: 2;
    color: #808080;
    font-weight: bold;
    flex-shrink: 0;

    &--default {
      color: #808080;
    }

    &--failed {
      color: #c8102e;
    }

    &--success {
      color: #008473;
    }
  }

  &__header {
    order: 1;
    word-break: break-all;
    font-size: 1rem;
  }

  &__status-bar {
    order: 3;
  }
}
