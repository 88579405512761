.popover{
    &__list{
        color: black;
        text-align: left;
        text-transform: capitalize;
        margin-left: 20px;
    }

    &__message{
        text-align: center;
        margin-bottom: 15px;
        padding: 10px;
    }

    &__content{
        opacity: 1;
        z-index: 10;
        margin-top: 30px;
        position: absolute;
        transform: translate(0, -20px);
        background-color: white;
        padding: 1.5rem;
        box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.26);
        transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
        border-radius: 5%;
        width: 350px;
        height: auto;
    }

    &__content::before{
        position: absolute;
        z-index: -1;
        content: "";
        right: calc(85% - 5px);
        top: -8px;
        border-style: solid;
        border-width: 0 20px 20px 20px;
        border-color: transparent transparent white transparent;
        transition-duration: 0.1s;
        transition-property: transform;
    }

    &__wrapper{
        position: relative;
        display: inline-block;
    }

    &__title{
        margin-left: 10px;
        text-align: center;
        text-decoration: underline;
        color: $sprk-purple;
    }
}