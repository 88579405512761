.sprk-c-Dropdown {
  z-index: 1999;

  .sprk-c-Dropdown__links {
    max-height: 60vh;
    overflow: auto;

    a:hover {
      color: unset;
      text-decoration: none;
    }
  }
}
