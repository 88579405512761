.sprk-c-Tabs {
  &__buttons {
    border-bottom: 1px solid #cfcfcf;
  }

  &__button {
    background-color: $sprk-gray-alternative;
    border-top: 0;
    border-bottom: 3px solid $sprk-gray-alternative;
    width: auto;
    font-weight: 300;
    padding-right: 30px;
    padding-left: 30px;

    @media all and (min-width: $sprk-tab-navigation-breakpoint) {
      flex: none;
      display: block;
    }

    @media all and (max-width: $sprk-tab-navigation-breakpoint) {
      flex: 1 1 auto;
      width: 100%;
      display: block;
    }

    &--active,
    &:focus {
      outline: none;
      background-color: $sprk-gray-alternative;
      color: $sprk-red;
      border-top: 0;
      border-bottom: 3px solid $sprk-red;
      font-weight: 500;
    }

    &:hover:not([disabled]) {
      color: $sprk-purple;
      border-top: 0;
      border-bottom: 3px solid $sprk-purple;
    }
  }

  &__button-closing_status {
    background-color: $sprk-white;
    width: 100%;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: x-large;

    &--active,
    &:focus {
      outline: none;
      background-color: $sprk-white;
      color: $sprk-red;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 3px solid $sprk-red;
      font-weight: 500;
    }

    &:hover:not([disabled]) {
      color: $sprk-purple;
      border-top: 0;
      border-bottom: 3px solid $sprk-purple;
    }

    &--readonly {
    pointer-events: none;
    background-color: $sprk-white;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    }
  }
}
