.accept-modal{
    top: 15%;
    box-shadow: none;
    max-width: 55%;
    overflow-y: initial;

  & .modal-content {
    border: 0;
  }

  & .data-list {
    overflow-y: auto;
  }
}