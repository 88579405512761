.sprk-c-Masthead {
  &__content {
    max-width: 100%;
    margin: auto;
    padding: 16px;
  }

  &__logo {
    width: $sprk-masthead-logo-max-width;
  }

  &__search {
    min-width: 200px;
  }
}
