.badging {
    position: relative;
    &__counter {
      position: absolute;
      font-size: 0.7em;
      color: $sprk-white;
      background-color: $sprk-purple;
      top: -3px;
      right: 0;
      width: 1.6rem;
      height: 1.6rem;
      text-align: center;
      border-radius: 50%;
      box-shadow: 0 0 1px #333;
      z-index: 100;
      display: flex;
      justify-content: center;
      align-items: center;

      &--blue {
        background-color: $sprk-blue;
      }

      &--green {
        background-color: $sprk-green;
      }

      &--red {
        background-color: $sprk-red;
      }
    }
  }
