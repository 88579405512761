.order-view {
  & &__menu {
    padding: $sprk-space-inset-l;
    background-color: white;
    box-shadow: $sprk-card-shadow;
    flex-flow: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;

    @media only screen and (min-width: 1200px) {
      max-width: 400px;
    }
  }
  &__subscriptions {
    display: inline-block;
    padding: 4px;
  }

  & &__content {
    @include display-flex;
    align-items: center;
    padding: $sprk-space-inset-l;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-flow: column;
    align-items: stretch;
    -webkit-transition: 1s;
    transition: 1s;
    &--route{
      padding: 0 10px;
    }
    & nav {
      flex-grow: 1;
    }
  }

  & &__sub-header {
    padding: 10px 0 20px 0;
    display: flex;
    flex-direction: row;
  }
}
.primary-contact {
  &--content {
    display: flex; 
    flex-direction: column;
    margin: 0 0 20px 0;
  }
}