.display-table-row {
  display: table-row !important;
}

.display-none {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: no-drop;
}

.overflow-y--scroll {
  overflow-y: scroll;
}

.white-space-pre-line {
  white-space: pre-line;
}

.multi-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &--short {
    -webkit-line-clamp: 1;
  }
}

.top--15 {
  top: 15%;
}

.line-height-3 {
  line-height: 3;
}

.margin-top-10-percent{
  margin-top: 10%;
}