:root {
   --padding: 50px;
   --mastHeight: 96px;
   --footerHeight: 0px;
   --maxHeight: calc(100vh - var(--mastHeight) - var(--footerHeight));
}

@media only screen and (min-width: 768px){
   .sticky {
      position: sticky; 
      display: flex;
      flex-grow: 1;
      &--full-height {
         max-height: var(--maxHeight);
      }
      &--top {
         flex-basis: 300px;
         align-self: start;
         top: calc(var(--mastHeight)); 
         overflow-y: auto;         
      }
      &__scroll {
         mask-image: linear-gradient(to top, transparent, black),
            linear-gradient(to left, transparent 12px, black 12px);
         mask-position: left top;
         mask-size: 100% 20000px;
      }
   }
}