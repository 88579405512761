:root {
   --scroll-width: 12px;
}

.scrollbar {
   &::-webkit-scrollbar-thumb {
      background-color: $sprk-black-tint-20;
      border-radius: calc(var(--scroll-width) * 2);
   }
   &::-webkit-scrollbar-track {
      background-color: $sprk-gray-alternative;
   }
   &::-webkit-scrollbar {
      width: var(--scroll-width);
   }
}