@import 'ngx-toastr/toastr';

.toast-success {
  background-color: $sprk-green;
}

.toast-error {
  background-color: $sprk-pink;
}

.toast-info {
  background-color: $sprk-blue;
}

.toast-warning {
  background-color: $sprk-pink;
}
