$value: 'sprk-c-Modal';

.#{$value} {
    & .modal-content {
        border: 0;
    } 
    
    &.#{$value}--alt {
        top: 15%;
        box-shadow: none;
        max-width :80rem;
    }

    &.#{$value}--hidden-scroll {
        overflow-y: hidden;
    } 
}