.btn-purple {
  border-color: $sprk-purple;
  background-color: white;
  color: black;
}

.dropdown {
  border-radius: 4px;
  border: 2px solid $sprk-black-tint-50;
  background-color: white;
  width: 100%;
  &:active, &:focus {
    border-color: $sprk-purple;
  }
  .c-token {
    color: white;
    background-color: $sprk-purple;
  }
  li:hover {  
    color: white;
    background-color: $sprk-purple;
  }
  .clear-all {
    display: none;
  }
}

.dropdown-large {
  width: 25rem;
}
