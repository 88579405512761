.search-modal {
  background-color: $sprk-black-tint-10;
  margin: 2rem 2rem;
  padding: 2rem;
  border: 0.05rem solid $sprk-black-tint-20;
  border-radius: 1.5rem;
  box-shadow: 0.2rem 0.2rem $sprk-black-tint-20;

  &__header {
    border: none;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    align-items: center;
    margin-bottom: 1rem;
    &-title {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    &__close {
      text-align: right;
    }
  }
}
