.modal-content {
  border: 0;
}

.modal-dialog {
  pointer-events: all;
}

.modal-open {
  padding-right: 0 !important;
  overflow-y: auto;
}
