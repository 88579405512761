.sprk-b-InlineSearch--slim {
  .sprk-b-TextInput--has-svg-icon {
    border-bottom: 1px solid $sprk-black-tint-20;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0;
    font-size: 20px;
    margin-right: 30px;
    &::placeholder {
      font-size: inherit;
    }
  }

  .sprk-b-TextInput:focus {
    box-shadow: none;
  }

  .sprk-b-InlineSearch__icon {
    stroke: $sprk-black;
  }
}

.sprk-b-TextInput[readonly] {
  &.sprk-b-TextInput--black {
    color: $sprk-black;
  }
}

.sprk-b-InputContainer {
  margin: 0;
  max-width: none;
}

.sprk-b-InputContainer .sprk-b-InputContainer__icon-container svg.sprk-c-Icon {
  margin-top: 16px;
  margin-left: 16px;
  position: absolute;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
