@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(30%);
  }

  70% {
    transform: translateY(-2%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-30%);
  }

  70% {
    transform: translateY(2%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(-30%);
  }

  70% {
    transform: translateX(2%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(30%);
  }

  70% {
    transform: translateX(-2%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.slide {
  animation: slide-up 0.7s ease-in-out;

  @media only screen and (max-width: 615px) {
    animation: none;
  }

  &--down {
    animation: slide-down 0.7s ease-in-out;
  }

  &--left {
    animation: slide-left 0.7s ease-in-out;
  }

  &--right {
    animation: slide-right 0.7s ease-in-out;
  }
}
