$value: 'drop-zone';

.#{$value} {
  width: 100%;
  min-height: 300px;
  margin: auto 0;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: $sprk-black 2px dashed;
  transition: ease-in 0.2s;
  -webkit-transition: ease-in 0.2s;
  -moz-transition: ease-in 0.2s;
  -ms-transition: ease-in 0.2s;
  -o-transition: ease-in 0.2s;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;

  &.#{$value}--drag-over {
    border-color: $sprk-black;
    opacity: 0.5;
  }

  & &__content {
    font-size: 1.7rem;
    text-align: center;
    width: 100%;
  }

  & &__icon {
    padding-right: 0.5em;
  }

  & ~ &__caption,
  & &__caption {
    color: #808080;
  }

  & &__label {
    display: inline-block;
    color: $sprk-purple;
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    padding: 0 2px;
    text-decoration: underline;

    &:hover {
      color: $sprk-black;
    }

    input {
      display: none;
    }
  }
}
