$value: 'sprk-c-Card';

.#{$value} {
  background: $sprk-white;
  max-width: inherit;

  &.#{$value}--full {
    max-width: 100%;
  }

  &.#{$value}--flat {
    box-shadow: none;
  }

  &.#{$value}--gray {
    background-color: $sprk-gray-alternative;
  }

  &.#{$value}--gray-alternative {
    background-color: $sprk-black-tint-70;
    color: $sprk-white;

    p,
    span {
      color: $sprk-white;
    }
  }

  &.#{$value}--border {
    border: 1px solid $sprk-red;
    border-radius: 1em;
  }
}
