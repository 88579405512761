.email-verify-loader {
  div {
    width: 5rem;
    height: 5rem;
  }
}

.sprk-c-Spinner--inline-block {
  display: inline-block !important;
}
