.max-width-90rem {
  max-width: 90rem;
}

.max-width-100rem {
  max-width: 100rem;
}

.max-width-50rem {
  max-width: 50rem;
}

.max-width-25rem {
  max-width: 25rem;
}

.max-width-1300-px {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.max-width-800-px {
  max-width: 800px;
}

.max-width-none {
  max-width: none;
}

.max-vh-70 {
  max-height: 70vh;
}

.min-vh-50 {
  min-height: 50vh;
}

.width-100-percent {
  width: 100%;
}

.width-80-percent {
  width: 80%;
}

.width-50-percent {
  width: 50%;
}

.width-auto {
  width: auto !important;
}

.min-width-120px {
  min-width: 120px;
}