.to-top__container {
   display: flex;
   flex-direction: row;
   align-items: stretch;   
   height: 50px;
   width: 50px;
   &--sticky {
      position: fixed;
      top: 50%;
      bottom: 50%;
      right: 10px;
      z-index: 3000;
   }
}

.to-top__button{
   display: flex;
   align-items: center;
   flex-direction: row;
   flex-grow: 1;
   cursor: pointer;
   text-align: center;
   border-radius: 10px;
   padding: 5px;
   background-color: $sprk-white;
   border:1px solid $sprk-red;
   color: $sprk-red;
   &__image {
      margin-top:-2px;
   }
}