.progress {
  padding: 0;
  height: 25px;
  overflow: hidden;
  background-color: $sprk-gray-alternative-light;
  border-radius: 6px;

  & &__bar {
    &--failed {
      background: $sprk-red;
    }

    position: relative;
    float: left;
    min-width: 1%;
    height: 100%;
    background: $sprk-purple;
  }

  & &__percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-size: 12px;
    color: white;
  }
}
