.messages{
   &__header {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   }
   &__filter {
      display: flex;
      &__label {
         padding:10px;
      }
   }
}

.new-message {
   &__button--contents {
      display: flex; 
      justify-content: center;
   }
   &__button--label {
      margin-bottom: 0;
      line-height: 24px;
   }
}

@media screen and (max-width: 1280px){
   h6 {
      font-size: 1.1rem !important;       
   }

   .resizable-h6 {
      padding: 1rem 0 !important;   
   }
}