$value: 'line-clamp';

.#{$value} {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  line-clamp: 3;
  -webkit-line-clamp: 3;

  &.#{$value}--expand {
    line-clamp: initial;
    -webkit-line-clamp: initial;
  }
}