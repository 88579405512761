.max-height-5rem {
  max-height: 5rem;
}

.max-height--70 {
  max-height: 70%;
}

.max-height--80-vh {
  max-height: 80vh;
}

.min-height--88vh {
  min-height: 88vh;
}

@media only screen and (min-width: 768px){
  .min-height--90vh {
    min-height: 90.5vh;
  }
}

.height--10vh {
  height: 9.5vh;
}

.height--100vh {
  height: 100vh;
}