.color--sprk-blue {
  color: $sprk-blue;
}

.color--sprk-green {
  color: $sprk-green;
}

.color--sprk-red {
  color: $sprk-red;
}

.color--sprk-yellow {
  color: $sprk-yellow;
}

.color--sprk-pink {
  color: $sprk-pink;
}

.color--sprk-black-tint-50 {
  color: $sprk-black-tint-50;
}

.color--sprk-black-tint-70 {
  color: $sprk-black-tint-70;
}

.color--sprk-purple {
  color: $sprk-purple;
}

.color--sprk-white {
  color: $sprk-white;
}

.color--sprk-black {
  color: $sprk-black;
}

.background-color--off-white {
  background-color: #f2f2f2;
}

.background-color--white {
  background-color: $sprk-white;
}

.background-color--sprk-gray-alternative-dark {
  background-color: $sprk-gray-alternative-dark;
}

.background-color--sprk-gray-alternative {
  background-color: $sprk-gray-alternative;
}

.background-color--sprk-purple {
  background-color: $sprk-purple;
}

.fill-sprk-purple {
  fill: $sprk-purple;
}

.fill-sprk-black {
  fill: $sprk-black;
}

.stroke-sprk-purple {
  stroke: $sprk-purple;
}
