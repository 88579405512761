.hero {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-flow: column;
  overflow: hidden;
  min-height: 480px;
  max-height: 500px;
  width: 100%;
  background-color: #333;
  border-radius: 2vh;

  &--image {
    background-image: url(https://images.pexels.com/photos/277559/pexels-photo-277559.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: overlay;
  }

  &--parallax {
    background-attachment: fixed;
  }

  &--center {
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
  }

  &__content {
    &--center {
      justify-self: center;
      align-self: center;
    }
  }

  &__title {
    &--center {
      text-align: center;
      padding: 1rem;
      color: white;
    }
  }
}
